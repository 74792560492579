import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import '../assets/less/who.less'

export default ({ data }) => {
  const metaDataTitle = data.wordpressPage.acf.metadata_title ? data.wordpressPage.acf.metadata_title : "Axel Olson"
  const metaDataDescription = data.wordpressPage.acf.metadata_description ? data.wordpressPage.acf.metadata_description : "Architect."
  const metaDataImg = data.wordpressPage.acf.metadata_image ? data.wordpressPage.acf.metadata_image.localFile.url : ''
  return (
    <Layout page={data.wordpressPage.title}>
      <SEO title={metaDataTitle}
           description={metaDataDescription}
           imageURL={metaDataImg}
      />
      <div className="who-page">
        <div className="who-page-portrait">
        {!data.wordpressPage.acf.hide_portrait_image ?
          <Img fixed={ data.wordpressPage.acf.portrait_image.localFile.childImageSharp.fixed } alt="Portrait Image" /> :
          <></>
        }
        </div>
        <div className="content-section">
          <div className="biography" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.biography}} />
          <div className="social-links" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.social_links}} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query {
  wordpressPage(title: { eq: "Who" }) {
    title
    id
    acf {
      portrait_image {
        localFile {
          childImageSharp {
            fixed(webpQuality: 10, height: 450) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      hide_portrait_image
      biography
      social_links
      metadata_title
      metadata_description
      metadata_image {
        localFile {
          url
        }
      }
    }
  }
}
`
